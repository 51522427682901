import React from 'react'

const Head = () => {
  return (
    <div>
        <section className="head">
            <div className="container flexSB">
                <div className="logo">
                    <h1>RESOURCEIFY</h1>
                    <span>THE HUB OF RESOURCES</span>
                </div>

                <div className="social">
                     <a href="https://www.buymeacoffee.com/nanayaw" target="_blank" rel="noreferrer"><i className="fas fa-coffee icon"></i></a>
                    <a href="https://github.com/nanaawesome" target="_blank" rel="noreferrer"><i className="fa-brands fa-github icon"></i></a>
                    <a href="https://www.linkedin.com/in/nana-yaw-asare-frimpong-855611107/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin-in icon"></i></a>
                </div>
            </div> 

        </section>
    </div>
  )
}


export default Head