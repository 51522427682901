import React from 'react'
import Hero from "./hero/Hero"



const Home = () => {
  return (
    <>
        <Hero />
    </>
  )
}

export default Home