import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from "react-ga4";
const TRACKING_ID = "G-5X47JV2VR3"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const rootElement = document.getElementById("root");


if (rootElement.hasChildNodes()) {
  const root = hydrateRoot(rootElement)
  root.render(<App/>);
} else {
  const root = createRoot(rootElement);
  root.render(<App/>);
}