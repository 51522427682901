import React from 'react'
import "./hero.css"
import Title from "../../common/title/Title"
import { Link } from "react-router-dom";


const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row desc">
            <Title subtitle="Best resource hub" title="WELCOME TO RESOURCEIFY"/>
            <p>
            To ensure your academic success, countless hours have been devoted to compiling an extensive library of websites and learning resources for students pursuing IGCSE, A-level, and International Baccalaureate programs.<br /><br />

            The website is set up by curriculum, making it simple for you to locate the materials you want for success in your particular curriculum. Or, you may use the handy search box if you have a certain subject in mind to find the website you're searching for right away.<br /><br />

            So don't put off your studies any longer and begin browsing our website to get the tools you need to succeed in IGCSE, A-level, or the International Baccalaureate. We want to support you every step of the way because we want to see you succeed.
            </p>
          </div>
        </div>
      </section>
      <div className="margin"></div>
      
      
    
    </>
  )
}

export default Hero