import React from 'react'
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai'
import './footer.css'

const Footer = () => {
  return (
    <div className="footer-container">
      <a href="https://www.buymeacoffee.com/nanayaw" target="_blank" rel="noreferrer"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-violet.png" alt="Buy Me A Coffee" className="coffee2"></img></a>
      <p>2022 Resourceify All rights reserved</p>
      <p className="icons">
        <a href="https://github.com/nanaawesome" target="_blank" rel="noreferrer"><AiFillGithub /></a>
        <a href="https://www.linkedin.com/in/nana-yaw-asare-frimpong-855611107/" target="_blank" rel="noreferrer"><AiFillLinkedin /></a> 
      </p>
    </div>
  )
}

export default Footer
